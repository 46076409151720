.bannerSection {
    border-radius: 16px;
    background: #D9D9D9;
    height: 200px;
    margin-top: 1.5rem;
}

.SearchBoxMain {
    max-width: 50%;
    margin-top: 1.5rem;
}

.TeamsSection {
    margin-top: 1.5rem;
}

.TeamCardMain {
    padding: 14px 16px;
    border-radius: 13px;
    background: var(--Akos-white, #FFF);
    border: 1px solid #EEE;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
}

.cardImg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5px;
}

.cardImg img {
    width: 116px;
    height: 116px;
    border-radius: 50%;
}

.cardImg h3 {
    font-size: 20px;
    color: #000;
    text-align: center;
}

.cardImg h4 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-top: 3px;
}

.cardOtherContent {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    flex-direction: column;
    margin-top: 3px;
}



.cardOtherContent p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.7;
}

.selectedMember h4 {
    color: var(--Akos-Dark-grey, #767676);
    font-size: 16px;
    margin-bottom: 16px;
}

.teamContentMain {
    position: relative;
}

.SelectMember {
    position: absolute;
    right: 0;

}

.checkBoxDiv {
    width: 20px;
    height: 20px;
    background: var(--Akos-Blue, #0562CC);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AllDocuments {
    margin-top: 2rem;
}

.DocumentIconAndcont {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.DocumentIconAndcont h3 {
    position: absolute;
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-weight: 600;
}

.DocumentInnerMain {
    border-radius: 9px;
    background: rgba(37, 64, 107, 0.10);
    padding: 14px 11px;
    cursor: pointer;
}

.DocFileBox {
    border-radius: 9px;
    background: var(--Akos-white, #FFF);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.VDocBoxHead p {
    color: #000;
    font-size: 14px;
    margin-bottom: 5px;
}

.viewDocumentHeader,
.viewDocumentHeader h3 {
    display: flex;
    align-items: center;
    gap: 30px;
}

.viewDocumentHeader h3 {
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    gap: 10px;
}

.viewDocumentHeader h3 span {
    font-weight: 600 !important;
    font-size: 15px;
}

.availabilityMain {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.availabilityBox {
    max-width: 80%;
    border-radius: var(--Radius-200, 8px);
    border: 1px solid #EEE;
    background: var(--Akos-white, #FFF);
    padding: 1.5rem;
}
.fileLists {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 1.5rem;
}

.fileLists li {
    display: flex;
    gap: 1rem;
}

.fileLists li span {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-weight: 400;
}

@media (max-width: 768px){
    .SearchBoxMain, .availabilityBox {
        max-width: 100%;
        width: 100%;
    }
}