.madicalRecordMain {
    margin-top: 1.5rem;
}

.medicalRecordUserSec {
    border-radius: 16px;
    border: 1.5px solid #EEE;
    background: #FAFBFC;
    padding: 16px 21px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
}

.userNameAndImg {
    display: flex;
    gap: 10px;
}

.userImg {
    width: 50px;
}

.userImg img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.usercontent h3 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-weight: 700;
}

.usercontent p {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
}

.userOtherData {
    text-align: center;
    min-width: 10%;
}

.fixMinWidth {
    min-width: 13%;
}

.userOtherData h4 {
    color: var(--Akos-Dark-grey, #767676);
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
}

.userOtherData p {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 13px;
    font-weight: 400;
    margin-top: 5px;
}

.appointmentssection,
.fileSection {
    border-radius: 16px;
    border: 1.5px solid #EEE;
    background: #FAFBFC;
}

.fileSection {
    padding: 16px 10px;
}

.fileLists {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 1.5rem;
}

.fileLists li {
    display: flex;
    gap: 1rem;
}

.fileLists li span {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-weight: 400;
}

.doctorSec {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.doctorSec img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.typeOfCallBtn {
    border-radius: 200px;
    border: 1.5px solid var(--Akos-Blue, #0562CC);
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.StatusBtn {
    border-radius: 8px;
    border: 1.5px solid var(--mChemist-dark-green, #54A276);
    background: #C0CDD0;
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: var(--mChemist-dark-green, #54A276);
    font-size: 14px;
    font-weight: 700;
    display: inline-flex !important;
}

.StatusBtnCancel {
    border-radius: 8px;
    border: 1.5px solid #AA2626;
    background: #C8C0C8;
    color: #AA2626;
}

.StatusBtnUpcoming {
    background: #FFF;
    border: 1.5px solid var(--Akos-Blue, #0562CC);
    color: var(--Akos-Blue, #0562CC);
}

.viewDetails p{
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    text-decoration: underline !important;
    cursor: pointer;
}
.completeTableRow th:first-child {
    width: 20%;
}

.completeTableRow th:last-child {
    width: 15%;
}

@media (max-width: 768px) {
    .medicalRecordUserSec {
      flex-direction: column;
    }
    .userOtherData {
      display: flex;
      gap: 16px;
      align-items: center;
    }
    .userOtherData p {
      font-weight: bold;
    }
  }