.doctorSec {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.doctorSec img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.CompletedBannerSec {
    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FBFBFB;
    margin-bottom: 1.5rem;
    padding: 1rem;
}

.bgImgsec img {
    margin-bottom: -1rem;
}

.bannerContentSec h4 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 20px;
    margin-top: 1.5rem;
}

.bannerContentSec h4 span {
    color: var(--mChemist-dark-green, #54A276);
    font-size: 20px;
    font-weight: 700;
}

.bannerContentSec img {
    height: 50px;
}

.buyNowCompleteTable {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.buyNowCompleteTable p,
.cancelAppointment {
    flex-wrap: nowrap;
    white-space: nowrap;
    text-decoration-line: underline;
    font-weight: 600 !important;
    cursor: pointer;
}

.downloadPresBtn {
    font-size: 14px !important;
    padding: 8px 10px !important;
    border-radius: 6px !important;
}

.completeTableRow th:first-child {
    width: 20%;
}

.completeTableRow th:nth-child(3) {
    width: 12%;
}

.SearchBoxMain {
    max-width: 50%;
    margin-top: 1.5rem;
}

.BAppointLeft::after {
    content: '';
    position: absolute;
    width: 1px;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-right: 1px solid #EEE !important;
}

.BADoctorData {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.BADdocImg img {
    width: 116px;
    height: 116px;
    border-radius: 50%;
}

.BADoctorData h4 {
    color: #000;
    font-size: 20px;
}

.BADoctorData h5 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.BADoctorData p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
}

.BADWatch {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

.BADWatch p {
    color: var(--Akos-Dark-grey, #767676);
    font-size: 14px;
    font-weight: 700;
}

.BCalenderHead h3 {
    font-size: 18px;
    padding-left: 1.5rem;
}

.availableAppointment {
    border-top: 1px solid #C9C9C9;
}

.availableApBox {
    border-radius: 8px;
    border: 1px solid var(--Akos-Black, #1A1A1A);
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.availableApBox p {
    font-size: 14px;
}

.selectedTime {
    background: var(--Akos-Blue, #0562CC);
}

.selectedTime p {
    color: var(--Akos-white, #FFF);
}

.BookThanksMain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.BookThanksMain img {
    height: 300px;
    object-fit: contain;
}

.BookThanksMain h3 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
}

.BookThanksMain p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
}

/* talk to doctor start  */
.TalkToDoctorMain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    padding-bottom: 0;
    cursor: pointer;
}

.talkTODocBox {
    border-radius: 16px;
    border: 2px solid var(--Akos-Black, #1A1A1A);
    background: var(--Akos-white, #FFF);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}

.talkTODocBox h3 {
    color: #000;
    font-size: 20px;
    font-weight: 700;
}

.talkTODocBox p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

/* talk to doctor end */
.appointmentDetailsPage {
    margin-top: 2rem;
}

.ADetailsRight {
    border-radius: 8px;
    border: 1px solid var(--Akos-Light-Grey, #C9C9C9);
    background: #FAFBFC;
    padding: 11px 21px;
    margin-top: 1.5rem;
}

.cheifCoHeadDes:first-child {
    margin-top: 0 !important;
}

.cheifCoHeadDes {
    margin-top: 1rem;
}

.cheifCoHeadDes h4 {
    color: var(--colors-base-black, #000);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0.4rem;
}

.cheifCoHeadDes p {
    color: var(--colors-base-black, #000);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}


.StatusBtn {
    border-radius: 8px;
    border: 1.5px solid var(--mChemist-dark-green, #54A276);
    background: #C0CDD0;
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: var(--mChemist-dark-green, #54A276);
    font-size: 14px;
    font-weight: 700;
    display: inline-flex !important;
    cursor: auto;
}

.MissedApp {
    border-radius: 8px;
    border: 1.5px solid #AA2626;
    background: #C8C0C8;
    color: #AA2626;
}

.StatusBtnPending {
    background: #FFF;
    border: 1.5px solid var(--Akos-Blue, #0562CC);
    color: var(--Akos-Blue, #0562CC);
}

.fileSection {
    padding: 16px 10px;
}

.fileLists {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 1.5rem;
}

.fileLists li {
    display: flex;
    gap: 1rem;
}

.fileLists li span {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-weight: 400;
}
