.dashboardLayout {
    max-width: 100%;
    width: 100vw;
    display: flex;
    overflow: hidden;
}

.pageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: calc(100% - 300px);
    margin-left: 300px;
    transition: width 0.3s ease, margin-left 0.3s ease;
}

.pageContainer.toggle {
    width: calc(100% - 80px);
    margin-left: 80px;
}

.dashboard_mainRapper {
    width: 100%;
    background: var(--Akos-white, #FFF);
    overflow: auto;
    height: calc(100vh - 77px);
}


.sidebarMain {
    width: 300px;
    height: 100vh;
    overflow: hidden;
    background: var(--Akos-white, #FFF);
    border-right: 1px solid #EEE;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.3s ease;
}

.sidebarMain.toggle {
    width: 80px;
}

.sidebarLogo,
.sidebarLogoToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.sidebarLogoToggle {
    height: 120px;
}

.sidebarLogo img {
    height: 90px;
}

.sidebarItemsMain {
    height: calc(100vh - 150px);
    overflow: auto;
}

.sidebarItemsMain::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.sidebarItemsMain li {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 10px;
    cursor: pointer;
    border-radius: 8px;
    padding: 7px 14px;
    overflow: hidden;
    white-space: nowrap;
    transition: padding 0.3s ease;
}

.sidebarMain.toggle .sidebarItemsMain li a {
    display: none;
}

.sidebarItemsMain li svg {
    width: 24px;
    height: 24px;
}

.sidebarItemsMain li a {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sidebarItemsMain .ActiveLink {
    background: var(--Akos-Blue, #0562CC);
}

.sidebarItemsMain .ActiveLink svg path {
    fill: #FFF !important;
}

.sidebarItemsMain .ActiveLink a {
    color: #FFF;
}

.sidebarMain.toggle .sidebarItemsMain .ActiveLink {
    justify-content: center;
}

/* header start */
.CustomContainer {
    padding: 10px 40px;
    background: var(--Akos-white, #FFF);
}

.headerSection {
    width: 100%;
    border-bottom: 1px solid #EEE;
}

.HeaderMain {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.hRightSection,
.ccmRpm {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: flex-end;
}

.HRTextIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    border-bottom: 5px solid transparent;
}

.HRShowHover {
    position: absolute;
    top: calc(100% + 5px);
    border: 1px solid #EEE;
    background: var(--Akos-white, #FFF);
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 999;
}

.HRListMain {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.HRListMain li {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
    font-weight: 400;
    list-style: none;
    white-space: nowrap;
    padding: 0 17px;
}

.HRListMain li:last-child {
    border-top: 1px solid #EEE;
    padding-top: 5px;
}

.ActiveHRTextIcon {
    border-bottom: 5px solid var(--Akos-Blue, #0562CC);
}

.ccmRpm {
    justify-content: unset;
    padding: 10px 0;
}

.ccmRpm h3 {
    font-size: 18px;
    font-weight: 600;
}

.ccmRpm h3 span {
    color: var(--Akos-Light-blue, #22B7BC);
    font-size: 20px;
    font-weight: 700;
}

.HRTextIcon {
    padding: 10px 0;
}

.HRTextIcon h3 {
    font-size: 14px;
    font-weight: 600;
}

.NotificationBoxMain {
    min-width: 450px;
}

.notificationBox {
    max-height: 50vh;
    overflow: auto;
}

.notificationBox::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #EEE;
}

.notificationBox::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 4px;
}

.notificationContentMain {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.NContentSec {
    cursor: auto;
}

.NContentSec h3 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 16px;
}

.NContentSec p {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 14px;
    padding-top: 3px;
}

.toogleBtnMain {
    display: flex;
    width: 34px;
    height: 34px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    background: var(--Akos-Blue, #0562CC);
    position: absolute;
    left: -17px;
    top: 85px;
    z-index: 999;
    cursor: pointer;
}

.pageContainer.toggle .toogleBtnMain svg {
    transform: rotate(180deg);
}

.SettingMain {
    margin-top: 2rem;
}

.SettingMain .changePassword {
    max-width: 50%;
    margin: auto;
}

.notificationCount {
    color: var(--Akos-Blue, #0562CC);
}
.profilePic{
    height: 32px;
}
/* header end */
/* responsive start */
@media (max-width: 1024px) {
    .CustomContainer {
        padding: 10px;
    }
    .pageContainer {
        width: calc(100% - 200px);
        margin-left: 200px;
    }

    .sidebarMain {
        width: 200px;
    }

    .sidebarMain.toggle {
        width: 60px;
    }

    .sidebarItemsMain li {
        gap: 10px;
    }

    .HRTextIcon h3 {
        font-size: 12px;
    }

    .sidebarItemsMain li a {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .sidebarMain {
        width: 200px;
        z-index: 999;
    }

    .toogleBtnMain {
        z-index: 999;
    }
    .toggle .toogleBtnMain{
        left: -34px;
    }

    .sidebarMain.toggle {
        width: 60px;
    }
    .HRShowHover{
        right: auto !important;
        max-width: 80vw;
        overflow: auto;
    }
    .SettingMain .changePassword{
        max-width: 100%;
    }
}

/* responsive end */