.CallNotiMain {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
}

.imgSec {
    width: 70px;
}

.userDetailsSec {
    width: calc(100% - 86px);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.imgSec img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.UNameSec {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.UName h3 {
    color: #1D2225;
    font-size: 15px;
    font-weight: 700;
}

.UName p {
    color: #1D2225;
    font-size: 14px;
    font-weight: 400;
}

.GName {
    text-align: center;
}

.GName h3,
.LName h3 {
    color: #929494;
    font-size: 15px;
    font-weight: 400;
}

.GName p,
.LName p {
    color: #1D2225;
    font-size: 16px;
    font-weight: 600;
}

.AdBtnMain {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.CallNotiBox {
    border-radius: var(--Radius-200, 8px);
    border: var(--sds-size-stroke-border) solid #FAFBFC;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem;
}

.sidebarMain {
    display: flex;
    gap: 16px;
    margin-top: 1.5rem;
    height: 100%;
}

.SLeftSec {
    width: calc(100% - 60px);
    border-radius: var(--Radius-200, 8px);
    background: var(--colors-base-white, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 1rem;
    height: 100%;
    max-height: 80vh;
    overflow: auto;
}

.SRightSec {
    border-radius: var(--Radius-200, 8px);
    background: var(--colors-base-white, #FFF);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
    height: 100%;
    width: 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding: 1rem;
}

.SIcons {
    border-radius: 6px;
    border: 1px solid #D1D5DB;
    background: #FFF;
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.SIcons.active,
.SIcons.active svg path {
    border: 1px solid var(--Color-Main, #0562CC);
    fill: #0562CC;
}

.videoCallAndSidebarMain {
    display: flex;
    gap: 16px;
}

.videoCallMain {
    width: calc(100% - 60px);
}

.sidebarSecMain {
    width: 60px;
}

.videoCallMain.ActiveTab {
    width: calc(100% - 484px);
}

.sidebarSecMain.ActiveTab {
    width: 500px;
}

.presMain {
    margin: 2rem 0;
}

.heading h3 {
    font-size: 24px;
    margin: 1rem 0;
}

.patientDetails h4 {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
}

.patientDetails span,
.patientDetails address {
    color: #1D2225;
    font-weight: 400;
    font-size: 16px;
}

.form {
    margin-top: 2rem;
}

.vitalMCard {
    border-radius: 8px;
    border: 1 solid var(--Primary-color-Primary-color200, #0562CC);
    padding: 11px 10px;
    background: var(--Akos-white, #FFF);
    box-shadow: 0px 0px 3.674px 0px rgba(165, 179, 143, 0.20);
}

.boxContMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.boxHead h4 {
    color: #050628;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.boxCont h4 {
    font-size: 25px;
    font-weight: 600;
}

.boxCont sub {
    color: var(--Grey-lighter-places, #8F98B3);
    font-size: 12px;
    font-weight: 600;
}

.boxCont p {
    color: var(--Black---dark-purple, #050628);
    font-size: 11px;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 600px){
    .videoCallMain.ActiveTab {
        width: 100%;
    }
    .videoCallAndSidebarMain{
        display: unset;
    }
    .sidebarSecMain.ActiveTab {
        width: 100%;
    }
}