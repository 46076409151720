@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif !important;
}

:root {
    --Akos-Blue: #0562CC;
    --Akos-Black: #1A1A1A;
    --Akos-white: #FFF;
    --Akos-Light-Grey: #C9C9C9;
}

body {
    background: var(--Akos-white, #FFF);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--Akos-Black, #1A1A1A);
    font-style: normal;
    line-height: normal;
    font-weight: 700;
}

p {
    color: var(--Akos-Black, #1A1A1A);
    font-style: normal;
    line-height: normal;
    font-weight: 400;
}

.custumTextField {
    border-radius: 16px;
    border: 1px solid #C9C9C9 !important;
    background: var(--Akos-white) !important;
    padding: 14px 26px !important;
    outline: none !important;
}

.TextFieldAll {
    border-radius: 10px !important;
    border: 1.5px solid #EEE !important;
    background: var(--Akos-white, #FFF) !important;
    padding: 10px 18px !important;
}

.custumTextField .MuiOutlinedInput-notchedOutline {
    display: none !important;
}

.custumTextField .MuiOutlinedInput-root,
.custumTextField .MuiOutlinedInput-root input {
    width: 100%;
    padding: 0 !important;
}

.custumTextField .MuiSelect-select {
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
    min-height: unset !important;
}
.forYearFilter .MuiSelect-select{
    padding-right: 32px !important;
}

.profileInput {
    border-radius: 5px !important;
    padding: 8px 16px !important;
}

.customFormLabel {
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    margin-bottom: 8px !important;
}

.errorMsg {
    color: #C51414;
    font-size: 16px;
    font-weight: 700;
}

.errorMsgInput {
    color: #C51414;
    font-size: 14px;
    font-weight: 400;
}

.OtpInputMain {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 455px;
}

.custumOtpinput,
.custumOtpinput:focus,
.custumOtpinput:focus-within {
    border-radius: 6px;
    border: 1.5px solid #EEE;
    background: var(--Akos-white, #FFF);
    width: 44px !important;
    height: 56px !important;
    outline: none;
    box-shadow: none;
}

::placeholder,
textarea::placeholder {
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.3;
}

.fillButton,
.fillButton:focus,
.fillButton:active,
.outLineBtn,
.outLineBtn:focus,
.outLineBtn:active {
    outline: none;
    box-shadow: none;
    border-radius: 16px;
    background: var(--Akos-Blue, #0562CC);
    display: flex;
    padding: 10px 26px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Akos-white, #FFF);
    font-size: 16px;
    font-weight: 700;
    flex-wrap: nowrap;
    white-space: nowrap;
    border: 1.5px solid transparent;
}

.whiteBgBtn,
.whiteBgBtn:focus,
.whiteBgBtn:active {
    background: var(--Akos-white, #FFF);
    color: var(--Akos-Blue, #0562CC);
    border-radius: 8px;
    padding: 5px 20px;
}

.font14Btn,
.font14Btn:focus,
.font14Btn:active {
    font-size: 14px;
}

.outLineBtn,
.outLineBtn:focus,
.outLineBtn:active {
    background: var(--Akos-white, #FFF);
    color: var(--Akos-Blue, #0562CC);
    border-radius: 9.718px;
    border: 1.5px solid var(--Akos-Blue, #0562CC);
}

.disableBtn,
.disableBtn:focus,
.disableBtn:active {
    background: var(--Akos-white, #FFF);
    color: var(--Akos-Light-Grey, #C9C9C9);
    border-radius: 9.718px;
    border: 1.5px solid #EEE;
}

.DeclineBtn,
.DeclineBtn:focus,
.DeclineBtn:active {
    background: var(--Akos-white, #FFF);
    color: #DE0035;
    border: 1.5px solid #F00;
}

.HeadAndButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeadAndButton h2 {
    font-size: 20px;
    color: #000;
}

.AllPageMain {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.customFileInput {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1
}

.customFileInputLabel {
    border-radius: 6px;
    border: 2px solid var(--Akos-Blue, #0562CC);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 8.2px 16px;
    flex-wrap: nowrap;
    white-space: nowrap;
    color: var(--Akos-Blue, #0562CC);
    font-size: 14px;
    font-weight: 700;
}

.customFileInputLabel span {
    width: 20px;
}

.customFileInputLabel img {
    margin-right: 8px
}

.customFileInputLabel span {
    color: #fff;
    font-family: var(--inter);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal
}

.fillBackground {
    background: var(--Akos-Blue, #0562CC);
    color: #FFF;
}

.custumTabs .MuiButtonBase-root {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize !important;
}

.custumTabs .MuiButtonBase-root.Mui-selected {
    color: var(--Akos-Black, #1A1A1A);
    font-weight: 700;
}

.custumTabs .MuiTabs-indicator {
    border-radius: 1px;
    background: var(--Akos-Blue, #0562CC) !important;
    height: 3px;
}

.custumTableHead {
    background: var(--Akos-Blue, #0562CC);
}

.custumTableHead th {
    color: var(--Akos-white, #FFF);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    border-bottom: 1px solid #EEE;
}

.medicineListTable {
    background: none;
}

.medicineListTable th {
    color: var(--mChemist-Black, #242424);
    border-bottom: none;
}

.custumTabPanel .MuiBox-root {
    padding: 18px 0 !important;
}

.nopaddingBottomTab .MuiBox-root {
    padding-bottom: 0 !important;
}

.TableContainer {
    box-shadow: none !important;
    background: none !important;
}

.TableContainer table {
    min-width: 1000px;
}

.custumTableRow th,
.custumTableRow th p,
.custumTableRow th span {
    border-bottom: 1px solid #EEE;
    color: var(--Akos-Black, #1A1A1A);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 1;
    text-align: center;
}

.medicineListTableRow th,
.medicineListTableRow th p,
.medicineListTableRow th span {
    border-bottom: none;
}

.custumTableRow th p,
.custumTableRow th span {
    border: none;
}

.custumTableRow th:last-child {
    /* display: flex;
    justify-content: flex-end; */
    text-align: center;
}

/* modal style */
.ModalBoxStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    background: var(--Akos-white, #FFF);
    padding: 20px;
    outline: none;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.modalHeader h3 {
    max-width: 85%;
    color: #000;
    font-size: 16px;
    font-weight: 700;
}

.modalHeader svg {
    cursor: pointer;
}

.confirmationBtns {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.confirmationBtns button {
    width: 50%;
}

/* modal style */
.MuiTooltip-tooltip {
    border-radius: 8px !important;
    border: 1.5px solid var(--Akos-Blue, #0562CC) !important;
    background-color: #FAFBFC !important;
    padding: 10px 14px !important;
}

.CustumTooltip .MuiTooltip-tooltip {
    color: #000 !important;
}

.justifyEnd {
    justify-content: flex-end !important;
}

.paddingTop0 {
    padding-top: 0 !important;
}

/* accordion start */

.CustomAccordion {
    border-radius: 8px;
    border: 1px solid #EEE;
    background: #FBFBFB;
    box-shadow: none !important;
    margin-top: 1rem;
}

.CustomAccordion:first-child {
    margin-top: 0 !important;
}

.CustomAccordion::before {
    display: none !important;
}

.AccordionSummary {
    justify-content: space-between !important;
}

.AccordionSummary .MuiAccordionSummary-content {
    max-width: calc(100% - 60px);
}

.AccordionSummary .MuiAccordionSummary-expandIconWrapper,
.AccordionSummary .Mui-expanded {
    width: 60px !important;
    display: flex;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

.AccordionSummary .MuiAccordionSummary-expandIconWrapper {
    justify-content: flex-end;
}

.AccordionHeader {
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 17px !important;
    font-weight: 400 !important;
}

.AccordionSummary .Mui-expanded {
    margin: 5px 0 !important;
}

.accordionDetails p {
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}

/* accordion end */
/* .hrLine {
    background: var(--Akos-Light-Grey, #C9C9C9);
    border: 0;
    border-top: 1px dotted #000; 
    border-spacing: 3px; 
    height: 1px;
    margin-top: 6px;
    margin-bottom: 6px;
} */
.hrLine {
    border: 0;
    border-top: 1px dotted transparent;
    border-image: url('../images/common/line.svg') 5 round;
    height: 1px;
    margin-top: 1.6rem;
    margin-bottom: 1rem;
    width: 100%;
}

.hrM4 {
    margin: 10px 0;
}

.AccordionSummary .Mui-expanded .AccordionHeader {
    font-weight: 600 !important;
}


.customRadio.Mui-checked {
    color: var(--Akos-Blue) !important;
}

.LinearProgress {
    border-radius: 6px;
    background: #EEE !important;
    height: 7px !important;
}

.MuiLinearProgress-bar {
    background: var(--Akos-Blue, #0562CC) !important;
}

.customFormControlLabel {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.nopaddingY {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* slider style */
.sliderContainer {
    width: 100%;
    margin: 0 auto;
}

.slick-slide {
    padding: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}

.slick-prev:before,
.slick-next:before {
    content: "";
    font-size: unset !important;
    color: unset !important;
    background: url('../images/common/arrow.svg') no-repeat;
    background-size: contain;
    width: 11px;
    height: 15px;
    display: inline-block;
}

.slick-prev,
.slick-next {
    z-index: 9;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    background: #BABABA !important;
    display: flex !important;
    justify-content: center;
    align-items: center
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    opacity: 0.35;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0.5 !important;
}

.slick-prev:hover,
.slick-next:hover {
    background: var(--Akos-Blue) !important;
}

.slick-prev:hover>.slick-prev:before,
.slick-next:hover>.slick-next:before {
    opacity: 1 !important;
}

.slick-prev:before {
    transform: rotate(180deg);
}

.slick-dots li button:before {
    font-size: 10px !important;
}


/* react slick end */

.socialHistoryTable td {
    border: none !important;
    padding: 8px 16px !important;
}

.socialHistoryTable .MuiFormControlLabel-labelPlacementEnd {
    margin-right: 0 !important;
}

.socialHistoryTableHead td {
    color: var(--Akos-Dark-grey, #767676);
    font-size: 16px;
    font-weight: 600;
}

.socialHistoryTableRow td {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.profileRadioLabel {
    color: var(--Akos-Dark-grey, #767676);
    font-size: 20px;
    font-weight: 600;
}

button {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
}

.fillButton:disabled {
    background: #B2C7E6 !important;
}

.joinNowForDisable:disabled {
    border: 1.5px solid #DDD;
    color: #DDD;
}

.customDateCalendar .Mui-selected {
    background-color: var(--Akos-Blue) !important;
    color: #fff !important;
    justify-content: center;
    align-items: center;
}

.customDateCalendar .MuiPickersYear-yearButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewDocumentPdf {
    max-height: 70vh;
    overflow: auto;
}

.viewDocumentPdf::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #EEE;
}

.viewDocumentPdf::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 4px;
}

.documentPrevNextPage,
.viewDocumentPdf .react-pdf__Page {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.documentPrevNextPage {
    padding: 10px;
}

.documentPrevNextPage p {
    background: #B2C7E6;
    padding: 10px;
    border-radius: 4px;
    color: #fff;
}

.ModalContentSection {
    max-height: 80vh;
    overflow-y: auto;
    padding-top: 2rem;
}

.ModalContentSection::-webkit-scrollbar {
    width: 0px;
}

.comkonTestsRadioGroup {
    gap: 16px;
}

.comkonTestsRadioLabel {
    border-radius: 4px;
    border: 1px solid #EEE;
    background: #FFF;
    display: flex;
    padding: 0 16px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    margin-left: 0 !important;
}

/* progress bar behavioural health */
.CircleScoreBox {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
    color: #000;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    background-color: #FFF;
    border-radius: 50%;
}

.useValuesss {
    font-size: 16px !important;
}

/* progress bar behavioural health */

.hideAsterisk .MuiFormControlLabel-asterisk {
    display: none !important;
    visibility: hidden !important;
}

.labTestRadio .MuiFormControlLabel-label {
    width: 100%;
}

.chatSearchInput {
    padding: 8px 14px !important;
    background-color: #FFF !important;
    border-radius: 10px !important;
}

.customCheckbox.Mui-checked {
    color: var(--Akos-Blue) !important;
}

.HeaderSwitch {
    width: 58px !important;
    height: 38px !important;
    padding: 6px !important;
}

.HeaderSwitch .MuiSwitch-switchBase.Mui-checked {
    color: var(--Akos-Blue) !important;
}

.HeaderSwitch .MuiSwitch-track {
    border-radius: 13px;
}

.customFileInputMain {
    display: flex;
    align-items: center;
    gap: 16px;
}

.filePreviewImg {
    display: flex;
    align-items: center;
    gap: 10px;
}

.filePreviewImg img {
    height: 60px;
}

.modalBody{
    max-height: calc(100vh - 200px);
    overflow: auto;
    padding: 2rem;
}
.modalBody::-webkit-scrollbar{
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #EEE;
}
.modalBody::-webkit-scrollbar-thumb{
    background: #D9D9D9;
    border-radius: 4px;
}
@media screen and (max-width: 1200px) {
    .OtpInputMain {
        width: unset;
        gap: 10px;
    }
    .ModalBoxStyle {
        max-width: 80%;
        max-height: 80vh;
        overflow: auto;
    }

}


@media (max-width: 768px) {
    .ModalBoxStyle {
        max-width: 80%;
        max-height: 80vh;
        overflow: auto;
    }

    .HeadAndButton {
        margin-bottom: 1rem;
    }

    .custumTabs .MuiTabs-flexContainer {
        overflow: auto;
    }
}

@media (max-width: 500px) {
    .HeadAndButton {
        flex-direction: column;
        gap: 16px;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 1rem;
    }
    .modalHeader h3{
        max-width: 100%;
    }

    .HeadAndButton button {
        align-self: flex-end;
    }
}